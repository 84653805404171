import React from "react";

import Tooltip from "@material-ui/core/Tooltip";

import { Link, withRouter } from "react-router-dom";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";

import BaseTable from "./BaseTable";
import StatesTable from "./StatesTable";

import DeclarationActions from "./cells/DeclarationActions";

import PreBoardingNotification from "../modal/PreBoardingNotification";
import Declaration from "../modal/Declaration";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

import SubmissionActions from "./SubmissionActions";

const CREATOR_FILTERS = {
  name: {
    api: "company_member_id",
    display: "Creator",
  },
  endpoint: {
    url: `${window.Api.CompanyMembers}`,
    filters: "",
    orderBy: "user__first_name",
    accessor: "",
  },
};

const STATUS_FILTERS = {
  name: {
    api: "status",
    display: "Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Draft",
      value: "draft",
    },
    {
      label: "Complete",
      value: "complete",
    },
    {
      label: "Processing",
      value: "processing",
    },
    {
      label: "Registered",
      value: "registered",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
    {
      label: "Under Review",
      value: "under_review",
    },
    {
      label: "Insufficient Funds",
      value: "insufficient_funds",
    },
    {
      label: "Released",
      value: "released",
    },
  ],
};

const ROUTE_FILTERS = {
  name: {
    api: "route",
    display: "Route",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Red",
      value: "red",
    },
    {
      label: "Green",
      value: "green",
    },
    {
      label: "Orange",
      value: "orange",
    },
    {
      label: "Yellow",
      value: "yellow",
    },
  ],
};

const CONSIGNOR_FILTERS = {
  name: {
    api: "consignor_id",
    display: "Consignor",
  },
  endpoint: {
    url: `${window.Api.Parties}`,
    filters: "type=exporter",
    accessor: "",
  },
};

const CONSIGNEE_FILTERS = {
  name: {
    api: "consignee_id",
    display: "Consignee",
  },
  endpoint: {
    url: `${window.Api.Parties}`,
    filters: "type=importer",
    accessor: "",
  },
};

const CLIENT_FILTERS = {
  name: {
    api: "client_id",
    display: "Client",
  },
  endpoint: {
    url: `${window.Api.Parties}`,
    filters: "type=client",
    accessor: "",
  },
};

const SUBTYPE_FILTERS = {
  name: {
    api: "form_subtype",
    display: "Type",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "H1",
      value: "H1",
    },
    {
      label: "H2",
      value: "H2",
    },
    {
      label: "H3",
      value: "H3",
    },
    {
      label: "H4",
      value: "H4",
    },
    {
      label: "H5",
      value: "H5",
    },
    {
      label: "H6",
      value: "H6",
    },
    {
      label: "H7",
      value: "H7",
    },
    {
      label: "I1",
      value: "I1",
    },
    {
      label: "G3/G4",
      value: "G4G3",
    },
    {
      label: "B1",
      value: "B1",
    },
    {
      label: "B2",
      value: "B2",
    },
    {
      label: "B4",
      value: "B4",
    },
    {
      label: "C1 C&F",
      value: "C1 C&F",
    },
    {
      label: "C1 B&E",
      value: "C1 B&E",
    },
  ],
};

const FILTERS = [
  CREATOR_FILTERS,
  STATUS_FILTERS,
  ROUTE_FILTERS,
  CONSIGNOR_FILTERS,
  CONSIGNEE_FILTERS,
  CLIENT_FILTERS,
  SUBTYPE_FILTERS,
];

class SubmissionsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      live: props.live,
      mode: props.mode,
      imports: props.imports,
      expandedSubmissionIds: [],
      selectedSubmissionIds: [],
    };

    this.table = React.createRef();
    this.statesTable = React.createRef();
  }

  _goTo(submission, edit = true) {
    this.setState({
      selectedSubmission: submission,
      declarationEditable: edit,
      showDeclarationModal: true,
    });
  }

  _collapse(submission) {
    let { expandedSubmissionIds } = this.state;
    let newExpandedSubmissionIds = expandedSubmissionIds.filter(
      (submissionId) => submission.id !== submissionId
    );
    this.setState({ expandedSubmissionIds: newExpandedSubmissionIds });
  }

  _expand(submission) {
    let { expandedSubmissionIds } = this.state;
    this.setState({
      expandedSubmissionIds: [...expandedSubmissionIds, submission.id],
    });
  }

  _isExpanded(submission) {
    let { expandedSubmissionIds } = this.state;

    return (
      expandedSubmissionIds.find(
        (submissionId) => submission.id == submissionId
      ) != null
    );
  }

  _getLastUpdatedBy(submission) {
    let updatedBy = submission.form.type == "ais" ? "Revenue" : "HMRC";
    let state = submission.latest_state;
    if (state.company_member) {
      let user = state.company_member.user;
      updatedBy = `${user.first_name} ${user.last_name}`;
    }
    return updatedBy;
  }

  _renderRoute(submission) {
    let className = "";
    if (submission.route === "green") {
      className = "svg-icon-light-success";
    } else if (submission.route === "red") {
      className = "svg-icon-light-danger";
    } else if (submission.route === "yellow") {
      className = "svg-icon-light-warning";
    } else if (submission.route === "orange") {
      className = "svg-icon-light-warning";
    }

    let route = (
      <span className={`svg-icon ${className}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
        </svg>
      </span>
    );

    if (
      ["draft", "complete", "processing", "rejected"].indexOf(
        submission.status
      ) > -1
    ) {
      route = null;
    }

    return route;
  }

  _renderSubmissionDetailColumn(title, subtitle, text) {
    return (
      <>
        <h4 className="text-muted fw-normal">{title}</h4>
        <p className="text-primary fw-light mb-0">{subtitle}</p>
        <p className="text-muted fw-light mb-0">{text}</p>
      </>
    );
  }

  _renderSubmission(submission, selected = false) {
    let route = this._renderRoute(submission);
    let showStatesTable = this._isExpanded(submission);
    let lastUpdatedBy = this._getLastUpdatedBy(submission);

    let className = "card card-submission";

    if (selected) {
      className += " card-submission-selected";
    }

    let content = null;

    content = (
      <SubmissionActions
        submission={submission}
        onNavigate={(submission, edit) => this._goTo(submission, edit)}
        onUploadClicked={() => {
          this.setState({
            selectedSubmission: submission,
            showDocumentsRequestedModal: true,
          });
        }}
        onPresentClicked={() => {
          this.setState({
            selectedSubmission: submission,
            showGoodsPresentationModal: true,
          });
        }}
        onPrintDeclarationClicked={() => {
          this.setState({
            selectedSubmission: submission,
            showDocumentsPresentationModal: true,
          });
        }}
        onUpdateSelectedIds={(ids) => {
          this.setState({
            formType: submission.form.type,
            selectedSubmissionIds: ids,
          });
        }}
        selectedSubmissionIds={this.state?.selectedSubmissionIds}
        goTo={(submission, edit) => this._goTo(submission, edit)}
        getColorClassNameForRoute={(route) =>
          this._getColorClassNameForRoute(route)
        }
      />
    );

    return (
      <>
        <div
          className={className}
          onClick={() => {
            if (this._isExpanded(submission)) {
              this._collapse(submission);
            } else {
              this._expand(submission);
            }
          }}
        >
          <div className="row">
            <div className="col-8 ids">
              {General.snakeCaseToTitleCase(submission.status)} {route}
              <div className="content">
                <h4 className="text-primary">ID: {submission.id}</h4>
                <h5 className="text-muted">MRN: {submission.mrn}</h5>
                <span className="text-muted fw-bold d-block fs-8">
                  {submission.external_id && (
                    <span className="text-muted fw-bold text-muted d-block fs-8">
                      UCR: <span>{submission.external_id} </span>
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className="col-4 text-end">
              <span className="text-dark text-hover-primary fs-6">
                {content}
              </span>
            </div>
          </div>
          <hr />
          <div className="row mt-2" />
          <div className="row">
            <div className="col-3">
              {this._renderSubmissionDetailColumn(
                "Consignor",
                submission.consignor?.name,
                submission.consignor?.identification_number
              )}
            </div>

            <div className="col-3">
              {this._renderSubmissionDetailColumn(
                "Consignee",
                submission.consignee?.name,
                submission.consignee?.identification_number
              )}
            </div>

            <div className="col-3">
              {this._renderSubmissionDetailColumn(
                "Type",
                submission.form.subtype,
                submission.form.type.toUpperCase()
              )}
            </div>

            <div className="col-3">
              {this._renderSubmissionDetailColumn(
                "Last Update",
                lastUpdatedBy,
                moment(submission.latest_state.created_at).format("lll")
              )}
            </div>
          </div>
        </div>

        <div className="w-100 pt-3 inner-states-table-container">
          {showStatesTable && (
            <>
              <DeclarationActions
                submission={submission}
                goTo={() => this._goTo(submission)}
                refresh={() => this.table.current.refresh()}
              />
              <StatesTable
                submission={submission}
                submissionID={submission.id}
                compact={true}
              />
            </>
          )}
        </div>
      </>
    );
  }

  _getColorClassNameForRoute(route) {
    let className = "secondary";
    if (route === "green") {
      className = "success";
    } else if (route === "red") {
      className = "danger";
    } else if (route === "yellow") {
      className = "warning";
    } else if (route === "orange") {
      className = "warning";
    }
    return className;
  }

  _getColumns() {
    let { selectedSubmissionIds } = this.state;

    return [
      {
        Header: "",
        id: "source.created_at",
        width: "100%",
        accessor: (submission) => {
          let submissionIndex = selectedSubmissionIds.findIndex(
            (submissionId) => submissionId === submission.id
          );
          let checked = submissionIndex > -1;
          return (
            <>
              <div className="row">
                <div className="col-6">
                  {this._renderSubmission(submission.source)}
                </div>
                <div className="col-6 r-sub">
                  {this._renderSubmission(submission, checked)}
                  <div className="form-check form-check-custom">
                    <Tooltip
                      title={
                        <p
                          style={{ fontSize: 12, padding: 8, marginBottom: 0 }}
                        >
                          Create PBN
                        </p>
                      }
                      fontSize={16}
                      placement="top"
                      arrow
                      className="mb-1"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={checked}
                        disabled_={
                          !submission.mrn ||
                          submission.pre_boarding_notification
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            selectedSubmissionIds.push(submission.id);
                          } else {
                            selectedSubmissionIds.splice(submissionIndex, 1);
                          }
                          this.setState({ selectedSubmissionIds });
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    ];
  }

  _renderPreBoardigNotificationButton() {
    let { selectedSubmissionIds } = this.state;
    if (selectedSubmissionIds.length == 0) {
      return null;
    }
    return (
      <a
        className="btn btn-light-primary btn-active-primary btn-send-pbn"
        onClick={() => {
          this.setState({ showPreBoardingNotificationModal: true });
        }}
      >
        <span className="svg-icon svg-icon-3">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.5"
              x="11.364"
              y="20.364"
              width="16"
              height="2"
              rx="1"
              transform="rotate(-90 11.364 20.364)"
              fill="currentColor"
            />
            <rect
              x="4.36396"
              y="11.364"
              width="16"
              height="2"
              rx="1"
              fill="currentColor"
            />
          </svg>
        </span>
        Create PBN
      </a>
    );
  }

  render() {
    let {
      mode,
      imports,
      selectedSubmissionIds,
      showPreBoardingNotificationModal,
      selectedSubmission,
      declarationEditable,
      showDeclarationModal,
    } = this.state;

    const columns = this._getColumns();

    return (
      <div className="linked-submissions-container">
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Submissions}?source_type=automated_import&expand_source=true`}
          noDataMessage={"No linked declarations found"}
          title={"Linked Declarations"}
          subtitle={this.props.subtitle}
          showPagination={true}
          columns={columns}
          filters={FILTERS}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          filterButtonName="Import Filters"
          dateRange={false}
          collapseOnDataChange={false}
          renderTableInnerHeader={() => (
            <div className="row p-3">
              <div className="col-6">
                <h2>Export</h2>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <h2>Automated Import</h2>
                  </div>
                  <div className="col-6 text-end">
                    {this._renderPreBoardigNotificationButton()}
                  </div>
                </div>
              </div>
            </div>
          )}
        />
        {showPreBoardingNotificationModal && (
          <PreBoardingNotification
            show={showPreBoardingNotificationModal}
            formType={"ais"}
            submissionIds={selectedSubmissionIds}
            onHide={() => {
              this.setState({ showPreBoardingNotificationModal: false });
            }}
            onSend={() => {
              this.setState({ showPreBoardingNotificationModal: false });
            }}
          />
        )}

        {showDeclarationModal && (
          <Declaration
            show={showDeclarationModal}
            editable={declarationEditable}
            submission={selectedSubmission}
            onClose={() => {
              if (declarationEditable) {
                this.table.current.refresh();
              }
              this.setState({
                showDeclarationModal: false,
                selectedSubmission: null,
                declarationEditable: false,
              });
            }}
          />
        )}
      </div>
    );
  }
}

SubmissionsTable.defaultProps = {
  showDateRange: true,
  subtitle: "",
  mode: "declaration",
};

export default withRouter(SubmissionsTable);
