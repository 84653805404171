import React from 'react'
import Modal from 'react-bootstrap/Modal'

import moment from "moment/moment";


import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";



export default class BulkUploadEdit extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      bulkUpload: General.clone(props.bulkUpload),
    }
  }



  save(){
    let { bulkUpload } = this.state

    this.setState({ loading: true })
    return Backend.updateBulkUpload(bulkUpload)
    .then(() => {
      this.props.onUpdated()
      this.setState({ loading: false })
      Notify.success("Bulk upload saved successfully")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({ loading: false })
    })
  }

  render() {
    let {
      show,
      loading,
      bulkUpload,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Bulk Upload
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row mb-5">
            <label className="form-label">Reference</label>
            <input
              type="text"
              className="form-control"
              placeholder="Reference"
              value={bulkUpload.external_id}
              onChange={e => {
                bulkUpload.external_id = e.target.value || null
                this.setState({ bulkUpload })
              }}
            />
          </div>

        </Modal.Body>

        <Modal.Footer>

          <div className='buttons-grid gg-7'>
            <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.save()}
                disabled={loading}
            >
              Save
            </button>
          </div>

        </Modal.Footer>

      </Modal>
    )
  }
}
