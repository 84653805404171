import React from "react"

import moment from "moment/moment";

import { withRouter } from "react-router-dom";

import Edit from "./cells/Edit";

import BaseTable from "./BaseTable"
import DeclarationsTable from "./SubmissionsTable"
import PreBoardingNotification from "../modal/PreBoardingNotification";


class PreBoardingNotificationsTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }


  _getColumns(){
    let columns = [
      {
        Header: "Date Created",
        accessor: pbn => moment(pbn.created_at).format('DD MMM YYYY HH:mm'),
        id: "created_at",
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: "ID",
        accessor: "external_id",
      },
      {
        Header: "Declarations No.",
        accessor: "submissions_no",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "",
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let preBoardingNotification = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
              <Edit
                onEditClicked={() => this.setState({
                  showPreBoardingNotificationModal: true,
                  selectedPreBoardingNotification: preBoardingNotification,
                })}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }


  render() {
    let {
      selectedPreBoardingNotification,
      showPreBoardingNotificationModal,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={window.Api.PreBoardingNotifications}
          noDataMessage={"No Pre Boarding Notifications found"}
          title={this.props.title}
          subtitle={this.props.subtitle}
          showFilter={false}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          SubComponent={(row) => {
            return (
              <DeclarationsTable
                title={"Declarations"}
                showDateRange={false}
                preBoardingNotification={row.original}
                mode={"declaration"}
              />
            )
          }}
        />
        { showPreBoardingNotificationModal &&
          <PreBoardingNotification
            show={showPreBoardingNotificationModal}
            preBoardingNotification={selectedPreBoardingNotification}
            onHide={() => {
              this.setState({ showPreBoardingNotificationModal: false });
            }}
            onSend={() => {
              this.setState({ showPreBoardingNotificationModal: false });
            }}
          />
        }
      </>
    )

  }

}

export default withRouter(PreBoardingNotificationsTable);
