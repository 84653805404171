import React from "react";
import Modal from 'react-bootstrap/Modal'

import Div from "../common/Div"
import AsyncSelect from "../common/AsyncSelect"
import CustomDropzone from "../common/CustomDropzone"


import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Declarant from "../../../utils/Declarant";

import PartyModal from "../modal/Party";

export default class Upload extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      parser: "platform",
      show: props.show,
      data: {},
      template: null
    }
  }

  _isFormValid(){
    let {
      file,
      client,
      parser,
      template,
    } = this.state

    let error = null
    if(!client){
      error = "Please select a client"
    }
    else if(!file){
      error = "Please upload a file"
    }
    else if(!parser){
      error = "Please specify the declaration type"
    }
    else if(!template){
      error = "Please select a template declaration"
    }

    if(error){
      Notify.error(error)
    }

    return error == null

  }

  _save(){
    if(!this._isFormValid()){
      return
    }

    let {
      file,
      client,
      parser,
      template,
      externalId,
      h1FallbackTemplate,
    } = this.state

    let data = {
      parser,
      file: file.id,
      client: client.id,
      external_id: externalId,
      template: template.id,
      declarant: Declarant.getDeclarantId(template.form)
    }

    if(h1FallbackTemplate){
      data.h1_fallback_template = h1FallbackTemplate.id
    }


    this.setState({ loading: true })
    Backend.createUpload(data)
    .then(bulkUpload => {
      this.setState({ file: null, loading: false }, () => this.props.onUploaded())
      Notify.success("File uploaded successfully, your declarations will become available as they are processed")
    }).catch(e => {
      Notify.error(e.message)
      this.setState({ loading: false })
    })
  }

  _renderContent(){
    let {
      client,
      loading,
      template,
      externalId,
      h1FallbackTemplate,
    } = this.state

    return (
      <>
        <div className="fv-row mt-10 mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
          <div className="dropzone dropzone-default dropzone-no-border">
            <CustomDropzone
              deletable={true}
              fileTypes={['.csv', '.xlsx']}
              onUploaded={file => {
                this.setState({file})
              }}
              onDelete={() => {
                this.setState({file: null})
              }}
            />
          </div>
        </div>

        <div className="form-group row mb-5">
          <label className="form-label ">Client</label>
          <AsyncSelect
            placeholder={"Please select a Client"}
            endpoint={window.Api.Parties}
            orderBy={"name"}
            filter={`type=client`}
            isClearable={true}
            creatable={true}
            className={'custom-async-select__container'}
            classNamePrefix={'custom-async-select'}
            value={General.getSingleAsyncOption(client)}
            getOptions={options => {
                return options.map(option => ({
                    value: option.id,
                    label: option.name,
                    data: option
                }))
            }}
            onSelected={client => {
              this.setState({
                client
              })
            }}
            onCreated={(option) => {
              let client = option.value;
              this.setState({
                client,
                showPartyModal: true,
              });
            }}
          />
        </div>

        <div className="form-group row mb-5">
          <label className="form-label ">Declaration</label>
          <AsyncSelect
            placeholder={"Please select a template"}
            endpoint={window.Api.Submissions}
            orderBy={"name"}
            filter={`template=true`}
            isClearable={false}
            className={'custom-async-select__container'}
            classNamePrefix={'custom-async-select'}
            value={General.getSingleAsyncOption(template)}
            getOptions={options => {
                return options.map(option => ({
                    value: option.id,
                    label: option.name,
                    data: option
                }))
            }}
            onSelected={template => {
              if(template?.form.subtype != "H7"){
                h1FallbackTemplate = null
              }
              this.setState({
                template,
                h1FallbackTemplate
              })
            }}
          />
        </div>

        { template?.form.subtype == "H7" &&
          <div className="form-group row mb-5">
            <label className="form-label ">H1 Fallback Declaration</label>
            <AsyncSelect
              placeholder={"Please select a template"}
              endpoint={window.Api.Submissions}
              orderBy={"name"}
              filter={`template=true&form_subtype=H1`}
              isClearable={false}
              className={'custom-async-select__container'}
              classNamePrefix={'custom-async-select'}
              value={General.getSingleAsyncOption(h1FallbackTemplate)}
              getOptions={options => {
                  return options.map(option => ({
                      value: option.id,
                      label: option.name,
                      data: option
                  }))
              }}
              onSelected={h1FallbackTemplate => {
                this.setState({
                  h1FallbackTemplate
                })
              }}
            />
            <span className="text-muted">The H1 declaration used if an item is prohibited on the H7.</span>
          </div>
        }

        <div className="form-group row mb-5">
          <label className="form-label ">Reference</label>
          <input
            type="text"
            className="form-control"
            placeholder="Reference"
            value={externalId}
            onChange={e => {
              externalId = e.target.value || null
              this.setState({externalId})
            }}
          />
        </div>
      </>
    )
  }

  render() {
    let {
      show,
      showPartyModal,
      loading,
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={'sub-modal'}
          dialogClassName={"modal-40"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Div disabled={loading}>


              { this._renderContent() }

            </Div>

          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              disabled={loading}
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              disabled={loading}
              onClick={() => this._save()}
            >
              Save
            </button>
          </Modal.Footer>

        </Modal>

        {showPartyModal && (
          <PartyModal
            name={"client"}
            show={showPartyModal}
            forcedPartyType={"client"}
            onHide={() => this.setState({ showPartyModal: false })}
            onSubmit={(client) => {
              this.setState({ showPartyModal: false, client });
            }}
          />
        )}
      </>
    )
  }

}