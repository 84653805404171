import React from "react";

import XMLViewer from "react-xml-viewer";

import Modal from "react-bootstrap/Modal";

export default class XmlModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      xml: props.xml,
    };
  }

  render() {
    let { show, xml } = this.state;

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={"sub-modal"}
          dialogClassName={"modal-70"}
        >
          <Modal.Header closeButton>
            <Modal.Title>XML Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <XMLViewer
              xml={xml}
              collapsible={true}
              initialCollapsedDepth={3}
              indentSize={4}
              theme={{
                tagColor: "#022624",
                textColor: "#d43900",
                attributeKeyColor: "#008000",
                attributeValueColor: "#2a7ab0",
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
