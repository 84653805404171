import General from './General'
import Declarant from './Declarant'
import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'

export default class Backend {

  static updateAccount(data){
    data = JSON.parse(JSON.stringify(data));
    return FetchHelper.patch(`${window.Api.CompanyMembers}/${data.user.id}`, data)
  }

  static updateCompany(data){
    data = JSON.parse(JSON.stringify(data));
    data.logo = data.logo?.id || null
    data.template_ais_submission = data.template_ais_submission?.id || null
    data.notifiable_contacts_for_route_cleared = data.notifiable_contacts_for_route_cleared.map(contact => contact.id)
    return FetchHelper.patch(`${window.Api.Companies}/${AuthManager.currentUser.company.id}`, data)
  }

  static subscribe(data){
    data = JSON.parse(JSON.stringify(data));
    return FetchHelper.patch(`${window.Api.Subcriptions}/${data.add_ons.subscription}`, data)
  }

  static addParty(party){
    let data = Backend._parseParty(party)
    return FetchHelper.post(window.Api.Parties, data)
  }

  static getHRMCAuthURL(returnUrl){
    let data = {
      certificate: {
        type: "hmrc",
        return_url: returnUrl
      }
    }
    return FetchHelper.post(window.Api.Parties, data)
  }

  static getForms(authorised, type=null){
    let url = `${window.Api.Forms}?authorised=${authorised}&paginated=false&order_by=name`
    if(type != null){
      url += `&type=${type}`
    }
    return FetchHelper.get(url)
  }

  static getTemplates(type=null){
    let url = `${window.Api.Submissions}?template=true&paginated=false&order_by=name`
    if(type != null){
      url += `&form_type=${type}`
    }
    return FetchHelper.get(url)
  }

  static createTemplate(data){
    data = JSON.parse(JSON.stringify(data))
    data.answers = []
    data.template = true

    data.declarant = Declarant.getDeclarantId(data.form)

    data.form = data.form?.id || null

    return FetchHelper.post(`${window.Api.Submissions}`, data)
    .then(template => {
      AuthManager.currentUser.company.onboarding_checklist.template = true
        return template
    })
  }

  static submitDeclaration(declaration){
    let data = JSON.parse(JSON.stringify(declaration))
    return FetchHelper.post(window.Api.Submissions, data)
  }

  static arrivalNotification(data){
    return FetchHelper.post(window.Api.ArrivalNotification, data)
  }

  static departureNotification(data){
    return FetchHelper.post(window.Api.DepartureNotification, data)
  }

  static presentGoods(submission){
    let data = {
      submission: submission.id,
    }
    return FetchHelper.post(window.Api.GoodsPresentation, data)
  }

  static getSubmission(submissionId){
    return FetchHelper.get(`${window.Api.Submissions}/${submissionId}`)
  }

  static updateDeclaration(submissionId, declaration){
    let data = JSON.parse(JSON.stringify(declaration))
    return FetchHelper.patch(`${window.Api.Submissions}/${submissionId}`, data)
  }

  static deleteSubmission(submissionId, reason){
    let data = { reason }
    return FetchHelper.delete(`${window.Api.Submissions}/${submissionId}`, {reason})
  }

  static getCodes(){
    return FetchHelper.get(`${window.Api.Codes}`)
  }

  static getFields(type, subType, formatTypes, excludeRepeatables = null){
    let url = `${window.Api.Fields}?form_type=${type}&form_subtype=${subType}&format_types=${formatTypes}&order_by=title&paginated=false`
    if(excludeRepeatables){
      url += `&exclude_repeatables=${excludeRepeatables}`
    }
    return FetchHelper.get(url)
  }

  static getParty(partyId){
    return FetchHelper.get(`${window.Api.Parties}/${partyId}`)
  }

  static updateParty(party){
    let data = Backend._parseParty(party)
    return FetchHelper.patch(`${window.Api.Parties}/${party.id}`, data)
  }

  static deleteParty(party){
    return FetchHelper.delete(`${window.Api.Parties}/${party.id}`)
  }

  static addCompanyMember(companyMember){
    let data = JSON.parse(JSON.stringify(companyMember));
    return FetchHelper.post(window.Api.CompanyMembers, data)
  }

  static updateCompanyMember(companyMember){
    let data = JSON.parse(JSON.stringify(companyMember));
    delete data.user.email
    return FetchHelper.patch(`${window.Api.CompanyMembers}/${companyMember.user.id}`, data)
  }

  static deleteCompanyMember(companyMember){
    return FetchHelper.delete(`${window.Api.CompanyMembers}/${companyMember.user.id}`)
  }

  static addAuthorisation(authorisation){
    let data = JSON.parse(JSON.stringify(authorisation));
    return FetchHelper.post(window.Api.Authorisations, data)
  }

  static updateAuthorisation(authorisation){
    let data = JSON.parse(JSON.stringify(authorisation));
    return FetchHelper.post(`${window.Api.Authorisations}/${authorisation.id}`, data)
  }

  static getList(listId){
    return FetchHelper.get(`${window.Api.Lists}/${listId}`)
  }

  static getListCodes(listKey){
    return FetchHelper.get(`${window.Api.Codes}?list_id=${listKey}`)
  }

  static getOverallStats(){
    return FetchHelper.get(`${window.Api.Stats}?overall=true`)
  }

  static _parseGuarantee(guarantee){
    let data = JSON.parse(JSON.stringify(guarantee));
    data.type = data.type.id
    data.references = data.references.map(reference => {
      reference.currency = reference.currency ? reference.currency.id : null
      return reference
    })
    return data
  }

  static addGuarantee(guarantee){
    let data = Backend._parseGuarantee(guarantee)
    return FetchHelper.post(window.Api.Guarantees, data)
  }


  static deleteGuarantee(guarantee){
    return FetchHelper.delete(`${window.Api.Guarantees}/${guarantee.id}`)
  }

  static updateGuarantee(guarantee){
    let data = Backend._parseGuarantee(guarantee)
    return FetchHelper.patch(`${window.Api.Guarantees}/${guarantee.id}`, data)
  }

  static getSubmissionShipmentItems(submission){
    return FetchHelper.get(`${window.Api.ShipmentItems}?submission_id=${submission.id}`)
  }

  static createUpload(data){
    return FetchHelper.post(window.Api.BulkUploads, data)
  }

  static _parseProduct(data) {
    let product = General.clone(data);

    product.procedure = product.procedure?.id || null;
    product.previous_procedure = product.previous_procedure?.id || null;
    product.preference = product.preference?.id || null;
    product.previous_document_type = product.previous_document_type?.id || null;
    product.commodity_code.taric = product.commodity_code.taric?.id || null;

    product.transaction_nature = product.transaction_nature?.id || null;

    product.valuation.preference = product.valuation.preference?.id || null;
    product.valuation.currency = product.valuation.currency?.id || null;
    product.valuation.indicator = product.valuation.indicator?.id || null;
    product.valuation.method = product.valuation.method?.id || null;

    product.dates_and_places.destination_country =
      product.dates_and_places.destination_country?.id || null;
    product.dates_and_places.destination_region =
      product.dates_and_places.destination_region?.id || null;
    product.dates_and_places.dispatch_country =
      product.dates_and_places.dispatch_country?.id || null;
    product.dates_and_places.origin_country =
      product.dates_and_places.origin_country?.id || null;
    product.dates_and_places.preferential_origin_country =
      product.dates_and_places.preferential_origin_country?.id || null;

    product.additional_procedures = product.additional_procedures.map(
      (additionalProcedure) => {
        additionalProcedure.code = additionalProcedure.code?.id || null;
        return additionalProcedure;
      }
    );

    product.documents = product.documents.map((document) => {
      document.type = document.type?.id || null;
      return document;
    });

    product.transport_documents = product.transport_documents.map(
      (transportDocument) => {
        transportDocument.type = transportDocument.type?.id || null;
        return transportDocument;
      }
    );

    product.additional_information = product.additional_information.map(
      (additionalInformation) => {
        additionalInformation.code = additionalInformation.code?.id || null;
        return additionalInformation;
      }
    );

    product.additional_references = product.additional_references.map(
      (additionalReference) => {
        additionalReference.type = additionalReference.type?.id || null;
        return additionalReference;
      }
    );

    product.additional_fiscal_references =
      product.additional_fiscal_references.map((additionalFiscalReference) => {
        additionalFiscalReference.code =
          additionalFiscalReference.code?.id || null;
        return additionalFiscalReference;
      });

    product.dangerous_goods = product.dangerous_goods.map(
      (dangerousGoodsItem) => {
        dangerousGoodsItem.type = dangerousGoodsItem.type?.id || null;
        return dangerousGoodsItem;
      }
    );

    product.commodity_code.national_additional_codes =
      product.commodity_code.national_additional_codes.map((additionalCode) => {
        additionalCode.code = additionalCode.code?.id || null;
        return additionalCode;
      });

    product.commodity_code.taric_additional_codes =
      product.commodity_code.taric_additional_codes.map((additionalCode) => {
        additionalCode.code = additionalCode.code?.id || null;
        return additionalCode;
      });

    product.packages = product.packages.map((pkg) => {
      pkg.type = pkg.type?.id || null;
      return pkg;
    });

    product.valuation.operations = product.valuation.operations.map(
      (operation) => {
        operation.code = operation.code?.id || null;
        return operation;
      }
    );

    product.y_codes = product.y_codes.map((yCode) => {
      return {
        code: yCode.code.id,
      };
    });

    return product;
  }

  static addProduct(product){
    let data = Backend._parseProduct(product)
    return FetchHelper.post(window.Api.Items, data)
  }

  static updateProduct(product){
    let data = Backend._parseProduct(product)
    return FetchHelper.patch(`${window.Api.Items}/${product.id}`, data)
  }

  static createShipmentItem(shipmentItem){
    let data = Backend._parseProduct(shipmentItem)
    return FetchHelper.post(window.Api.ShipmentItems, data)
  }

  static _parseAdditionalInformation(additionalInformations){
    return additionalInformations.map(additionalInformation => {
      additionalInformation.type = additionalInformation.type?.id || null
      return additionalInformation
    })
  }

  static _parseSupportingDocuments(supportingDocuments){
    return supportingDocuments.map(supportingDocument => {
      supportingDocument.file = supportingDocument.file?.id || null
      return supportingDocument
    })
  }

  static submitDocuments(data){
    let payload = General.clone(data)
    payload = {
      submission: payload.submission,
      supporting_documents: payload.records.map(record => record.supporting_document),
      additional_information: payload.records.map(record => record.additional_information)
    }
    payload.supporting_documents = Backend._parseSupportingDocuments(payload.supporting_documents)
    payload.additional_information = Backend._parseAdditionalInformation(payload.additional_information)
    return FetchHelper.post(window.Api.DocumentsRequest, payload)
  }

  static getDefaultPackage(){
    return FetchHelper.get(`${window.Api.PlatformPackages}?default=true`)
  }

  static getProduct(id){
    return FetchHelper.get(`${window.Api.Items}/${id}`)
  }

  static deleteProduct(product){
    return FetchHelper.delete(`${window.Api.Items}/${product.id}`)
  }

  static _parseParty(party){
    let data = General.clone(party);
    if(data.authorisation){
      data.authorisation = data.authorisation.id
    }

    if(data.type !== "representative"){
      delete data.indirect_representative
    }

    data.signed_form = data.signed_form?.id || null
    data.additional_declaration_type = data.additional_declaration_type?.id || null
    data.preferred_payment_method = data.preferred_payment_method?.id || null
    data.customs_office_of_lodgement = data.customs_office_of_lodgement?.id || null

    return data
  }

  static uploadFile(file, endpoint, params){
    let data = new FormData()

    data.append("file", file, file.name)
    for(var key in params){
      data.append(key, params[key])
    }

    return FetchHelper.post(endpoint, data, true)
  }

  static addAttachment(data){
    return FetchHelper.post(window.Api.Attachments, data)
  }

  static deleteAttachment(attachment){
    return FetchHelper.delete(`${window.Api.Attachments}/${attachment.id}`)
  }


  static uploadImage(image, metadata){
    let file = null

    let meta = {
      name: image.name.toLowerCase(),
      height: Math.round(metadata.height),
      width: Math.round(metadata.width),
    }

    return Backend.getPreSignedUploadUrl(meta)
    .then(response => {
      file = response.image;
      return FetchHelper.put(response.pre_signed_url, image, false, false, false)
    })
    .then(() => Backend.markImageUploaded(file.id, file.token))
  }

  static getPreSignedUploadUrl(data){
    return FetchHelper.post(window.Api.Images, data);
  }

  static markImageUploaded(id, token) {
    let data = {uploaded: true, token}
    return FetchHelper.patch(`${window.Api.Images}/${id}`, data);
  }

  static uploadFile(file, endpoint, params){
    let data = new FormData()

    data.append("file", file, file.name)
    for(var key in params){
      data.append(key, params[key])
    }

    return FetchHelper.post(endpoint, data, true)
  }

  static sendPreBoardingNotification(data){
    return FetchHelper.post(window.Api.PreBoardingNotifications, data);
  }

  static updatePreBoardingNotification(pbn, data){
    return FetchHelper.patch(`${window.Api.PreBoardingNotifications}/${pbn.id}`, data);
  }

  static createReport(data){
    data = General.clone(data);
    data.form = data.form.id
    return FetchHelper.post(window.Api.Reports, data);
  }

  static getSumbissionIds(formType, formSubType, additionalExternalIds){
    let url =`${window.Api.Submissions}/retrieve`
    url += `?form_type=${formType}&form_subtype=${formSubType}&additional_external_ids=${additionalExternalIds}`
    return FetchHelper.get(url).then(response => response.submission_ids)
  }

  static bulkAmend(formType, formSubType, submissionIds, answers, reason){
    answers = General.clone(answers)
    answers = answers.map(answer => {
      answer.field = answer.field?.id || null
      return answer
    })
    let data = {
      reason,
      answers,
      form_type: formType,
      form_subtype: formSubType,
      submission_ids: submissionIds,
    }
    return FetchHelper.put(window.Api.Answers, data)
  }

  static generate(type, submission){
    let url =`${window.Api.Submissions}/${submission.id}/${type}`
    return FetchHelper.get(url)
  }

  static isPBNApplicable(submissionId){
    let url =`${window.Api.Submissions}/${submissionId}/pbn-applicable`
    return FetchHelper.get(url)
  }

  static getSecretKey(){
    return FetchHelper.get(`${window.Api.SecretKey}`)
  }

  static createSecretKey(){
    return FetchHelper.post(`${window.Api.SecretKey}`)
  }

  static rotateSecretKey(){
    return FetchHelper.patch(`${window.Api.SecretKey}`)
  }
}
