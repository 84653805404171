import React, { Component } from "react";

import View from "./cells/View";
import Edit from "./cells/Edit";
import Duplicate from "./cells/Duplicate";
import SelectCell from "./cells/Select";
import DocumentsUploadRequest from "./cells/DocumentsRequest";
import GoodsPresentationRequest from "./cells/GoodsPresentationRequest";
import PrintDeclaration from "./cells/PrintDeclaration";

class SubmissionActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submission: props.submission,
    };
  }

  renderDraftActions(submission) {
    const { goTo } = this.props;

    return (
      <div className="text-end">
        <View
          onViewClicked={() => {
            goTo(submission, false);
          }}
        />
        <Edit
          onEditClicked={() => {
            goTo(submission, true);
          }}
        />
        <Duplicate submission={submission} />
      </div>
    );
  }

  renderTemplateActions(submission) {
    const { goTo } = this.props;

    return (
      <div className="text-end">
        <Edit onEditClicked={() => goTo(submission, true)} />
        <Duplicate submission={submission} />
      </div>
    );
  }

  renderSubmittedActions(submission) {
    const {
      onUploadClicked,
      onPresentClicked,
      onPrintDeclarationClicked,
      onUpdateSelectedIds,
      selectedSubmissionIds,
      goTo,
      getColorClassNameForRoute,
    } = this.props;

    const colorClassName = getColorClassNameForRoute
      ? getColorClassNameForRoute(submission?.route)
      : "secondary";

    let selectable =
      submission.form.type != "cds" && !submission.pre_boarding_notification;
    if (submission.form.subtype == "G4G3" || !submission.mrn) {
      selectable = false;
    }

    return (
      <div className="text-end">
        {submission.documents_upload_requested && (
          <DocumentsUploadRequest
            colorClassName={colorClassName}
            onUploadClicked={() =>
              onUploadClicked && onUploadClicked(submission)
            }
          />
        )}
        {submission.goods_presentation_status === "requested" && (
          <GoodsPresentationRequest
            colorClassName={colorClassName}
            onPresentClicked={() =>
              onPresentClicked && onPresentClicked(submission)
            }
          />
        )}
        {submission.documents_presentation_requested && (
          <PrintDeclaration
            onPrintDeclarationClicked={() =>
              onPrintDeclarationClicked && onPrintDeclarationClicked(submission)
            }
          />
        )}
        <View onViewClicked={() => goTo(submission, false)} />
        <Duplicate submission={submission} />
        {selectable && (
          <SelectCell
            key={submission.id}
            id={submission.id}
            tooltip="Add To PBN"
            ids={selectedSubmissionIds}
            onUpdated={(ids) => onUpdateSelectedIds && onUpdateSelectedIds(ids)}
          />
        )}
      </div>
    );
  }

  render() {
    const { submission } = this.state;

    if (submission.template) {
      return this.renderTemplateActions(submission);
    } else if (
      submission.status === "draft" ||
      submission.status === "rejected"
    ) {
      return this.renderDraftActions(submission);
    } else {
      return this.renderSubmittedActions(submission);
    }
  }
}

export default SubmissionActions;
