import React from "react";

import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable";

import SelectCell from "./cells/Select";


class PBNSubmissionsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSubmissions: [],
      preBoardingNotification: props.preBoardingNotification,
    };

    this.table = React.createRef();
  }

  _getColumns() {

    const COLUMN_MRN = {
      Header: "MRN",
      accessor: "mrn",
    };

    const COLUMN_UCR = {
      Header: "UCR",
      accessor: "external_id",
    };

    const COLUMN_LRN = {
      Header: "LRN",
      accessor: "lrn",
    };

    const COLUMN_ACTIONS = {
      Header: "",
      id: "",
      sortable: false,
      exportable: false,
      Cell: (rowInfo) => {
        let submission = rowInfo.original;
        let { selectedSubmissions } = this.state

        let selected = selectedSubmissions.map(s => s.id).includes(submission.id)

        let className = selected ? "btn-outline-primary" : "btn-outline-danger"
        return (
          <div key={submission.id} className={"w-100 text-right"}>
            <a
              className={`btn ${className} btn-sm me-2 text-hover-white`}
              style={{ marginLeft: "auto"}}
              onClick={e => {
                let newSelectedSubmissions = [...selectedSubmissions]
                if (selected) {
                  let index = newSelectedSubmissions.findIndex(o => o == submission.id)
                  newSelectedSubmissions.splice(index, 1)
                } else {
                  newSelectedSubmissions.push(submission)
                }
                this.setState({
                  selectedSubmissions: newSelectedSubmissions
                })
                this.props.onUpdatedSelectedSubmissions(newSelectedSubmissions)
              }}
            >
              { selected ? "Undo" : "Remove"}
            </a>
          </div>
        );
      },
    };

    return [
      COLUMN_MRN,
      COLUMN_UCR,
      COLUMN_LRN,
      COLUMN_ACTIONS
    ]
  }


  render() {
    const columns = this._getColumns();

    let endpoint = `${window.Api.Submissions}?template=false`;

    let preBoardingNotification = this.props.preBoardingNotification;
    if (preBoardingNotification) {
      endpoint += `&pre_boarding_notification_id=${preBoardingNotification.id}`;
    }

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={endpoint}
          noDataMessage={"No Declarations Found"}
          title={"Existing Declarations On PBN"}
          subtitle={"Click 'Remove' to select any and all declarations you'd like to remove"}
          showPagination={true}
          columns={columns}
          chipsSearch={true}
          sendExportAsEmail={true}
          exportButtonsEnabled={false}
          defaultSorted={[
            {
              id: "mrn",
              desc: true,
            },
          ]}
        />

      </>
    );
  }
}

PBNSubmissionsTable.defaultProps = {
  showDateRange: true,
  subtitle: "",
  mode: "declaration",
};

export default withRouter(PBNSubmissionsTable);
